/* You can add global styles to this file, and also import other style files */
* {
    font-family: Helvetica;
}
body{
    padding-top: 2em;
}

.menu {
    position: sticky;
    top: 0;
}

.menu-right{
    right: auto;
    float: right;
    width: 100%;
}

.title {
    position: sticky;
    top: 0;
}

.title-left{
    left: auto;
    float: left;
}

.section-view{
    margin-top: 2em;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;

}

.list-item-container{
    padding: 1em;
    border-bottom: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ccc;
}

.mat-list-item{
    height: auto!important;
}

.full-width{
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom : 2px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
